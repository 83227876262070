.ThankYou {

  background-color: $color_brand;

  .section {
    padding: 100px;

    .title {
      font-size: 40px;
      text-align: center;
    }

    .content {
      padding: 50px 400px;
      @include respond-to(mobile) {
        padding: 50px 0;
      }
      @include respond-to(tablet) {
        padding: 50px 200px;
      }
      @include respond-to(desktop) {
        padding: 50px 400px;
      }
      @include respond-to(large-desktop) {
        padding: 50px 500px;
      }
      text-align: center;

      p {
        margin-top: 50px;
      }

    }
  }
}