.Landing{
  background-color: $color_brand;

  .section{
    padding: 100px;
    @include respond-to(mobile) {
      padding: 20px;
    }
    .title{
      font-size: 40px;
      text-align: center;
    }
    .content{
      padding: 50px 400px;
      @include respond-to(mobile) {
        padding: 50px 0;
      }@include respond-to(tablet) {
        padding: 50px 200px;
      }@include respond-to(desktop) {
        padding: 50px 400px;
      }@include respond-to(large-desktop) {
        padding: 50px 500px;
      }


      .subContent{
        padding: 20px 0 20px;
      }
      .userinfo{
        text-align: center;
        padding: 50px;

      }

      .error{
        color: red;
        font-style: italic;
        font-size: 12px;
        display: block;
        text-align:center;

      }
      .btn{
        text-align: center;
      }
      .options{
        padding: 50px 100px;
        @include respond-to(mobile) {
          padding: 50px 0;
        }/*@include respond-to(tablet) {
          padding: 50px 0;
        }*/
        @include respond-to(large-desktop) {
          padding: 50px 200px;
        }


        .PositionTitle{
          padding: 20px 0;
        }
        .Discipline{
          padding: 20px 0;
        }
      }
    }
  }

  .ReactForm__Group{
    padding-bottom: 50px;
    padding-left: 25px;
  }

  .ReactForm__Input{
    width: 325px;
    height: 40px;
    padding: 0 25px;
  }

  Button{
    font-size: 16px;
    display: inline-flex;
    margin-top: 20px;
    .continueBtn{
      padding-left: 3px;
      line-height: 12px;
      font-size: 18px;
    }

  }

  .ReactForm__Select{
    width: 325px;
  }
  .ReactForm__Select option:first-of-type {
    pointer-events: none;
    color: grey;
    cursor: initial;
  }

}