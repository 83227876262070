@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ovo&display=swap');

/*
 * Variables
 */

$color_brand: #F2F5FA;
$color_brand--light: #602D89;

$color_bg-light-green: #D9EAE4;
$color_bg-light-grey: #F9FAFB;
$color_bg-medium: #E5E5FF;
$color_bg-light: #FFF;
$color_font-dark: #050038;
$color_bg-dark: $color_font-dark;

$color_font-light: #FFF;

$color_dark-blue: #58B4E5;
$color_blue: #3BBAED;
$color_light-blue: #98CAED;
$color_dark-green: #7AC141;
$color_green: #6BC2B8;
$color_light-green: #ABD788;
$color-dark-orange: #F58320;
$color_orange: #FFB000;
$color_light-orange: #F8A45E;
$color_dark-pink: #A9188D;
$color_light-pink: #C76CAF;
$color_pale-pink: #CBBED5;
$color_yellow: #FEF5A0;
$color_dark-red: #F27D77;
$color_light-red: #FFD8D6;
$color_mustard-yellow: #F1C307;
$color_text-blue: #6666FF;

$font_family-primary: 'Inter', sans-serif;
$font_family-secondary: 'Ovo', serif;

$font_size--base: 16px;
$font_size-small: 1rem;       // 16px
$font_size-regular: 1rem;    // 14px
$font_size-medium: 1.25rem;   // 20px
$font_size-large: 1.5rem;     // 24px
$font_size-larger:  2.625rem; // 42px
$font_size-largest: 5rem;     // 80px

$box_radius-small: 5px;
$box_radius-medium: 15px;
$box_radius-large: 6.25rem; // 100px
$box_shadow: 0 0 5px rgba($color_font-dark, .4);

$dims_padding-small: .9375rem;  // 15px
$dims_padding-medium: 3.125rem; // 50px
$dims_padding-large: 6.25rem;   // 100px


/*
 * Mixins + Extends
 */

$mobileBreak: 885px;
$tabletBreak: 1085px;
$largeBreak: 1650px;

$dims_button-size: 40px; // 40px
$dims_padding: 10px;     // 10px
$box_border-radius--small: 5px;


/*
 * Mixins + Extends
 */

$smallBreak:550px;
$mobileBreak: 885px;
$tabletBreak: 1085px;
$largeBreak: 1650px;

@mixin respond-to($media) {
  @if $media == mobile {
    @media all and (max-width: $smallBreak) { @content; }
  }
  @else if $media == tablet {
    @media all and (min-width: $mobileBreak+1px) { @content; }
  }
  @else if $media == desktop {
    @media all and (min-width: $tabletBreak+1px) { @content; }
  }
  @else if $media == large-desktop {
    @media all and (min-width: $largeBreak) { @content; }
  }
}

._vertical-align {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

html, body {
  position: relative;
  margin: 0;
  background-color: $color_brand;
  color: $color_font-dark;
  font-family: $font_family-primary;
  /* font-size: $font_size--base * .85;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow: hidden;
}


/*
 * Globals
 */

/*
html, body {
  position: relative;
  margin: 0;
  color: $color_font-dark;
  font-family: $font_family-primary;
  font-size: $font_size--base * .85;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;

  @include respond-to(desktop) {
      font-size: $font_size--base;
  }
}

*, *:before, *:after {
    position: relative;
    box-sizing: inherit;
    line-height: 1.2em;
}

ul {
    padding-left: 0;
    list-style: none;
}

a {
    text-decoration: none;
}*/
