.Card {
  display: flex;
  padding: 10px;
  /*margin: 10px 0;*/


  .items {
    width: 100%;
    display: flex;
    height: 40px;
    border: 2px solid black;
    border-radius: 5px;
    margin-left: 5px;

    .module {
      min-width: 15px;

    }

    .subItems {
      display: flex;
      padding: 10px;
      width: 100%;
      font-weight: 500;

      .description {
        padding: 0 5px;

        svg {
          width: 1.05rem;
          height: 1.05rem;
          @include respond-to(mobile) {
            width: 0.8rem;
            height: 0.8rem;
          }
          vertical-align: middle;
          color: $color_text-blue;
        }
      }

      .courseName {
        font-weight: 500;
        font-size: 14px;
        @include respond-to(mobile) {
          font-size: 10px;
        }
      }

      .hours {
        margin-left: auto;
        border-radius: 20px;
        padding: 5px 10px;
        background-color: $color_brand;
        line-height: 10px;
        font-size: 12px;
        @include respond-to(mobile) {
          font-size: 10px;
        }
        /*order: 3;*/
      }
    }
  }

  label {
    top: 10px;
    position: relative;

    input {
      cursor: pointer;
      height: 17px;
      width: 17px;

    }
  }


}