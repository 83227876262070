@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ovo&display=swap");
/*
 * Variables
 */
/*
 * Mixins + Extends
 */
/*
 * Mixins + Extends
 */
._vertical-align {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

html, body {
  position: relative;
  margin: 0;
  background-color: #F2F5FA;
  color: #050038;
  font-family: "Inter", sans-serif;
  /* font-size: $font_size--base * .85;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow: hidden;
}

/*
 * Globals
 */
/*
html, body {
  position: relative;
  margin: 0;
  color: $color_font-dark;
  font-family: $font_family-primary;
  font-size: $font_size--base * .85;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;

  @include respond-to(desktop) {
      font-size: $font_size--base;
  }
}

*, *:before, *:after {
    position: relative;
    box-sizing: inherit;
    line-height: 1.2em;
}

ul {
    padding-left: 0;
    list-style: none;
}

a {
    text-decoration: none;
}*/
.Landing {
  background-color: #F2F5FA;
}
.Landing .section {
  padding: 100px;
}
@media all and (max-width: 550px) {
  .Landing .section {
    padding: 20px;
  }
}
.Landing .section .title {
  font-size: 40px;
  text-align: center;
}
.Landing .section .content {
  padding: 50px 400px;
}
@media all and (max-width: 550px) {
  .Landing .section .content {
    padding: 50px 0;
  }
}
@media all and (min-width: 886px) {
  .Landing .section .content {
    padding: 50px 200px;
  }
}
@media all and (min-width: 1086px) {
  .Landing .section .content {
    padding: 50px 400px;
  }
}
@media all and (min-width: 1650px) {
  .Landing .section .content {
    padding: 50px 500px;
  }
}
.Landing .section .content .subContent {
  padding: 20px 0 20px;
}
.Landing .section .content .userinfo {
  text-align: center;
  padding: 50px;
}
.Landing .section .content .error {
  color: red;
  font-style: italic;
  font-size: 12px;
  display: block;
  text-align: center;
}
.Landing .section .content .btn {
  text-align: center;
}
.Landing .section .content .options {
  padding: 50px 100px;
  /*@include respond-to(tablet) {
    padding: 50px 0;
  }*/
}
@media all and (max-width: 550px) {
  .Landing .section .content .options {
    padding: 50px 0;
  }
}
@media all and (min-width: 1650px) {
  .Landing .section .content .options {
    padding: 50px 200px;
  }
}
.Landing .section .content .options .PositionTitle {
  padding: 20px 0;
}
.Landing .section .content .options .Discipline {
  padding: 20px 0;
}
.Landing .ReactForm__Group {
  padding-bottom: 50px;
  padding-left: 25px;
}
.Landing .ReactForm__Input {
  width: 325px;
  height: 40px;
  padding: 0 25px;
}
.Landing Button {
  font-size: 16px;
  display: inline-flex;
  margin-top: 20px;
}
.Landing Button .continueBtn {
  padding-left: 3px;
  line-height: 12px;
  font-size: 18px;
}
.Landing .ReactForm__Select {
  width: 325px;
}
.Landing .ReactForm__Select option:first-of-type {
  pointer-events: none;
  color: grey;
  cursor: initial;
}

.CourseSelection {
  padding: 20px;
}
.CourseSelection .ribbon {
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background-color: #ffffff;
}
@media all and (max-width: 550px) {
  .CourseSelection .ribbon {
    height: 80px;
  }
}
.CourseSelection .ribbon .title {
  display: flex;
  align-items: center;
  z-index: 2;
  width: max-content;
  height: 50px;
  position: fixed;
  top: 15px;
  /*      height: $dims_button-size + $dims_padding;*/
  padding: 5px 10px;
  background: #050038;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(5, 0, 56, 0.4);
}
@media all and (max-width: 550px) {
  .CourseSelection .ribbon .title {
    width: 90%;
  }
}
.CourseSelection .ribbon .title img {
  height: 35px;
}
.CourseSelection .ribbon .title h4 {
  width: 13.636rem;
  margin: auto;
  color: #FFF;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
}
.CourseSelection .ribbon .hours {
  display: flex;
  font-weight: 500;
  margin-left: auto;
  order: 2;
  align-items: center;
}
@media all and (max-width: 550px) {
  .CourseSelection .ribbon .hours {
    top: 75px;
    /* left: 5px; */
    padding: 5px;
    font-size: 10px;
    position: fixed;
  }
}
.CourseSelection .ribbon .hours .selectedHrs {
  color: #6666FF;
}
.CourseSelection .ribbon .hours .maxHrs {
  color: #050038;
}
.CourseSelection .filter-toggle {
  top: -5px;
}
.CourseSelection .filter-toggle .ReactForm__CheckboxSlider {
  margin-left: 10px;
}
.CourseSelection .indFilterLabel {
  margin-left: 10px;
  /*padding: 5px 10px 5px 0;*/
  font-size: 1.1rem;
  display: inline-block;
  box-sizing: border-box;
  height: 25px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 5px 0 1px;
  overflow: visible;
  border-radius: 20px;
  border: 1px solid #7d7a96;
  /* {"name":"09"} */
}
.CourseSelection .indFilterLabel label {
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  white-space: pre;
  font-weight: 500;
  font-style: normal;
  font-family: "Inter", sans-serif;
  color: #050038;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1;
  text-transform: uppercase;
  text-align: left;
}
.CourseSelection .indFilterLabel label input {
  cursor: pointer;
  height: 17px;
  width: 17px;
}
.CourseSelection .indFilterLabel label .chartTitle {
  display: inline-block;
  line-height: 25px;
  vertical-align: top;
  margin-left: 3px;
}
.CourseSelection .indFilterLabel.disabled {
  border-color: #ccc6f9;
}
.CourseSelection .indFilterLabel.disabled label {
  color: #ccc6f9;
}
.CourseSelection .indFilterLabel:first-of-type {
  margin-left: 0;
}
.CourseSelection .panel {
  display: flex;
  margin-top: 20px;
}
@media all and (max-width: 550px) {
  .CourseSelection .panel {
    display: block;
  }
}
.CourseSelection .panel .leftPanel {
  width: 20%;
  height: 100%;
  padding: 10px;
  font-weight: 500;
}
@media all and (max-width: 550px) {
  .CourseSelection .panel .leftPanel {
    width: 100%;
  }
}
.CourseSelection .panel .rightPanel {
  background-color: #ffffff;
  width: 80%;
  height: 100%;
  padding: 5px;
  margin-left: 10px;
  border-radius: 10px;
}
@media all and (max-width: 550px) {
  .CourseSelection .panel .rightPanel {
    width: 100%;
  }
}
.CourseSelection .panel .rightPanel .tier1 {
  display: flex;
  font-weight: 500;
  /*img{
    position: relative;
    top: -5px;
  }*/
}
.CourseSelection .panel .rightPanel .tier1 svg {
  width: 24px;
  height: 24px;
}
.CourseSelection .panel .rightPanel .tier1 .title {
  vertical-align: middle;
  margin-left: 10px;
  font-size: 18px;
  line-height: 24px;
}
@media all and (max-width: 550px) {
  .CourseSelection .panel .rightPanel .tier1 .title {
    font-size: 14px;
  }
}
.CourseSelection .panel .rightPanel .tier1 .filter {
  background-color: #F2F5FA;
  border-radius: 20px;
  font-size: 14px;
  margin-left: auto;
  order: 1;
  padding: 5px 10px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 25px;
  /*flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 25px;
  margin-left: 15px;
  padding: 0 5px 0 1px;

  font-size: 1.1rem;*/
}
@media all and (max-width: 550px) {
  .CourseSelection .panel .rightPanel .tier1 .filter {
    font-size: 10px;
  }
}
.CourseSelection .panel .rightPanel .tier2 {
  padding: 10px 0;
  overflow: visible;
  border-color: #F2F5FA;
  border-style: solid;
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.CourseSelection .panel .rightPanel .cardCollection {
  overflow-y: auto;
}
@media all and (max-width: 550px) {
  .CourseSelection .panel .rightPanel .cardCollection {
    height: 425px;
  }
}
.CourseSelection .submitBtn {
  margin-left: auto;
  order: 2;
  height: 30px;
  z-index: 2;
  font-size: 16px;
  display: flex;
  align-items: center;
  top: 5px;
  right: 5px;
}
@media all and (max-width: 550px) {
  .CourseSelection .submitBtn {
    font-size: 10px;
    width: 120px;
    top: 68px;
    position: fixed;
    right: 25px;
  }
}
.CourseSelection .submitBtn .submit {
  padding-left: 5px;
  display: inline;
  vertical-align: middle;
  line-height: 12px;
  font-size: 18px;
}
.CourseSelection .Checkbox {
  border: 2px solid #6666FF;
  display: inline-flex;
  top: 10px;
}
.CourseSelection .ReactForm__CheckboxSlider-input {
  position: relative;
}

.Card {
  display: flex;
  padding: 10px;
  /*margin: 10px 0;*/
}
.Card .items {
  width: 100%;
  display: flex;
  height: 40px;
  border: 2px solid black;
  border-radius: 5px;
  margin-left: 5px;
}
.Card .items .module {
  min-width: 15px;
}
.Card .items .subItems {
  display: flex;
  padding: 10px;
  width: 100%;
  font-weight: 500;
}
.Card .items .subItems .description {
  padding: 0 5px;
}
.Card .items .subItems .description svg {
  width: 1.05rem;
  height: 1.05rem;
  vertical-align: middle;
  color: #6666FF;
}
@media all and (max-width: 550px) {
  .Card .items .subItems .description svg {
    width: 0.8rem;
    height: 0.8rem;
  }
}
.Card .items .subItems .courseName {
  font-weight: 500;
  font-size: 14px;
}
@media all and (max-width: 550px) {
  .Card .items .subItems .courseName {
    font-size: 10px;
  }
}
.Card .items .subItems .hours {
  margin-left: auto;
  border-radius: 20px;
  padding: 5px 10px;
  background-color: #F2F5FA;
  line-height: 10px;
  font-size: 12px;
  /*order: 3;*/
}
@media all and (max-width: 550px) {
  .Card .items .subItems .hours {
    font-size: 10px;
  }
}
.Card label {
  top: 10px;
  position: relative;
}
.Card label input {
  cursor: pointer;
  height: 17px;
  width: 17px;
}

.ThankYou {
  background-color: #F2F5FA;
}
.ThankYou .section {
  padding: 100px;
}
.ThankYou .section .title {
  font-size: 40px;
  text-align: center;
}
.ThankYou .section .content {
  padding: 50px 400px;
  text-align: center;
}
@media all and (max-width: 550px) {
  .ThankYou .section .content {
    padding: 50px 0;
  }
}
@media all and (min-width: 886px) {
  .ThankYou .section .content {
    padding: 50px 200px;
  }
}
@media all and (min-width: 1086px) {
  .ThankYou .section .content {
    padding: 50px 400px;
  }
}
@media all and (min-width: 1650px) {
  .ThankYou .section .content {
    padding: 50px 500px;
  }
}
.ThankYou .section .content p {
  margin-top: 50px;
}

.ToolTip {
  font-size: 12px;
}
.ToolTip .tooltip-container {
  --tooltipBackground: #000;
  --tooltipBorder: #c0c0c0;
  --tooltipColor: #fff;
  background-color: var(--tooltipBackground);
  border-radius: 5px;
  border: 1px solid var(--tooltipBorder);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
  color: var(--tooltipColor);
  display: flex;
  flex-direction: column;
  padding: 0.4rem;
  transition: opacity 0.3s;
  z-index: 9999;
  width: 200px;
}
.ToolTip .tooltip-container[data-popper-interactive=false] {
  pointer-events: none;
}
.ToolTip .tooltip-arrow {
  height: 1rem;
  position: absolute;
  width: 1rem;
  pointer-events: none;
}
.ToolTip .tooltip-arrow::before {
  border-style: solid;
  content: "";
  display: block;
  height: 0;
  margin: auto;
  width: 0;
}
.ToolTip .tooltip-arrow::after {
  border-style: solid;
  content: "";
  display: block;
  height: 0;
  margin: auto;
  position: absolute;
  width: 0;
}
.ToolTip .tooltip-container[data-popper-placement*=bottom] .tooltip-arrow {
  left: 0;
  margin-top: -0.4rem;
  top: 0;
}
.ToolTip .tooltip-container[data-popper-placement*=bottom] .tooltip-arrow::before {
  border-color: transparent transparent var(--tooltipBorder) transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
  position: absolute;
  top: -1px;
}
.ToolTip .tooltip-container[data-popper-placement*=bottom] .tooltip-arrow::after {
  border-color: transparent transparent var(--tooltipBackground) transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
}
.ToolTip .tooltip-container[data-popper-placement*=top] .tooltip-arrow {
  bottom: 0;
  left: 0;
  margin-bottom: -1rem;
}
.ToolTip .tooltip-container[data-popper-placement*=top] .tooltip-arrow::before {
  border-color: var(--tooltipBorder) transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
  position: absolute;
  top: 1px;
}
.ToolTip .tooltip-container[data-popper-placement*=top] .tooltip-arrow::after {
  border-color: var(--tooltipBackground) transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
}
.ToolTip .tooltip-container[data-popper-placement*=right] .tooltip-arrow {
  left: 0;
  margin-left: -0.7rem;
}
.ToolTip .tooltip-container[data-popper-placement*=right] .tooltip-arrow::before {
  border-color: transparent var(--tooltipBorder) transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
}
.ToolTip .tooltip-container[data-popper-placement*=right] .tooltip-arrow::after {
  border-color: transparent var(--tooltipBackground) transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
  left: 6px;
  top: 0;
}
.ToolTip .tooltip-container[data-popper-placement*=left] .tooltip-arrow {
  margin-right: -0.7rem;
  right: 0;
}
.ToolTip .tooltip-container[data-popper-placement*=left] .tooltip-arrow::before {
  border-color: transparent transparent transparent var(--tooltipBorder);
  border-width: 0.5rem 0 0.5rem 0.4em;
}
.ToolTip .tooltip-container[data-popper-placement*=left] .tooltip-arrow::after {
  border-color: transparent transparent transparent var(--tooltipBackground);
  border-width: 0.5rem 0 0.5rem 0.4em;
  left: 3px;
  top: 0;
}

.Closed {
  background-color: #F2F5FA;
}
.Closed .section {
  padding: 100px;
}
.Closed .section .title {
  font-size: 40px;
  text-align: center;
}
.Closed .section .content {
  padding: 50px 400px;
  text-align: center;
}
@media all and (max-width: 550px) {
  .Closed .section .content {
    padding: 50px 0;
  }
}
@media all and (min-width: 886px) {
  .Closed .section .content {
    padding: 50px 200px;
  }
}
@media all and (min-width: 1086px) {
  .Closed .section .content {
    padding: 50px 400px;
  }
}
@media all and (min-width: 1650px) {
  .Closed .section .content {
    padding: 50px 500px;
  }
}
.Closed .section .content p {
  margin-top: 50px;
}

