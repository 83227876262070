.CourseSelection {
  padding: 20px;

  .ribbon {
    overflow: hidden;
    display: flex;
    @include respond-to(mobile) {
      height: 80px;
    }
    width: 100%;
    height: 40px;
    border-radius: 5px;

    background-color: #ffffff;

    .title {
      display: flex;
      align-items: center;
      z-index: 2;
      width: max-content;
      height: 50px;
      position: fixed;
      top: 15px;


      /*      height: $dims_button-size + $dims_padding;*/
      padding: 5px 10px;
      background: $color_bg-dark;
      border-radius: $box_border-radius--small;
      box-shadow: $box_shadow;
      @include respond-to(mobile) {
        width: 90%;
      }

      img {
        height: $dims_button-size * .875;
      }

      h4 {
        width: 13.636rem;
        margin: auto;

        color: $color_font-light;
        font-weight: 500;
        font-size: $font_size-regular;
        text-align: center;
      }
    }

    .hours {
      display: flex;
      font-weight: 500;
      margin-left: auto;
      order: 2;
      align-items: center;

      @include respond-to(mobile) {
        top: 75px;
        /* left: 5px; */
        padding: 5px;
        font-size: 10px;
        position: fixed;
      }

      .selectedHrs {
        color: $color_text-blue;
      }

      .maxHrs {
        color: $color_font-dark;
      }

    }
  }

  .filter-toggle {
     top: -5px;

     .ReactForm__CheckboxSlider {
       margin-left: $dims_padding;
     }
  }

  .indFilterLabel {
    margin-left: 10px;
    /*padding: 5px 10px 5px 0;*/
    font-size: 1.1rem;
    display: inline-block;

    box-sizing: border-box;
    height: 25px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 5px 0 1px;
    overflow: visible;
    border-radius: 20px;
    border: 1px solid #7d7a96; /* {"name":"09"} */

    label {
      cursor: pointer;
      flex-shrink: 0;
      overflow: visible;
      white-space: pre;
      font-weight: 500;
      font-style: normal;
      font-family: "Inter", sans-serif;
      color: #050038;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 1;
      text-transform: uppercase;
      text-align: left;


      input {
        cursor: pointer;
        height: 17px;
        width: 17px;

      }

      .chartTitle {
        display: inline-block;
        line-height: 25px;
        vertical-align: top;
        margin-left: 3px;
      }
    }

    &.disabled {
      border-color: #ccc6f9;

      label {
        color: #ccc6f9;
      }
    }
  }

  .indFilterLabel:first-of-type{
    margin-left: 0;
  }



  .panel {
    display: flex;
    @include respond-to(mobile) {
      display: block;
    }
    margin-top: 20px;

    .leftPanel {
      width: 20%;
      @include respond-to(mobile) {
        width: 100%;
      }
      height: 100%;
      padding: 10px;
      font-weight: 500;
    }

    .rightPanel {
      background-color: #ffffff;
      width: 80%;
      @include respond-to(mobile) {
        width: 100%;
      }
      height: 100%;
      padding: 5px;
      margin-left: 10px;
      border-radius: 10px;


      .tier1 {
        display: flex;
        font-weight: 500;
        svg{
          width: 24px;
          height: 24px;
        }
        /*img{
          position: relative;
          top: -5px;
        }*/
        .title{
          vertical-align:middle;
          margin-left: 10px;
          font-size: 18px;
          line-height: 24px;
          @include respond-to(mobile) {
            font-size: 14px;
          }
        }


        .filter {
          background-color: $color_brand;
          border-radius: 20px;
          font-size: 14px;
          @include respond-to(mobile) {
            font-size: 10px;
          }
          margin-left: auto;
          order: 1;
          padding: 5px 10px;

          display: inline-flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          height: 25px;

          /*flex-direction: row;
          justify-content: center;
          align-items: center;

          height: 25px;
          margin-left: 15px;
          padding: 0 5px 0 1px;

          font-size: 1.1rem;*/



        }
      }
      .tier2{
        padding: 10px 0;
        overflow: visible;
        border-color: #F2F5FA;
        border-style: solid;
        border-top-width: 2px;
        border-bottom-width: 2px;
        border-left-width: 0px;
        border-right-width: 0px;
      }

      .cardCollection{
        overflow-y: auto;
        @include respond-to(mobile) {
          height: 425px;
        }
      }
    }
  }

  .submitBtn {
    margin-left: auto;
    order: 2;
    height: 30px;
    z-index: 2;
    font-size: 16px;
    display: flex;
    align-items: center;
    top: 5px;
    right: 5px;
    @include respond-to(mobile) {
      font-size: 10px;
      width: 120px;
      top: 68px;
      position: fixed;
      right: 25px;
    }



    .submit {
      padding-left: 5px;
      display: inline;
      vertical-align: middle;
      line-height: 12px;
      font-size: 18px;

    }
  }
  .Checkbox{
    border: 2px solid $color_text-blue;
    display: inline-flex;
    top: 10px;
  }

  .ReactForm__CheckboxSlider-input {
    position: relative;
  }

}